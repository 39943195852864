<template>
  <van-popup
    :close-on-click-overlay="false"
    v-model:show="innerShow"
    position="bottom"
    round
    :style="{ height: '68%' }"
  >
    <div class="rules-regulations">
      <div class="rules-regulations__title">
        规章制度
      </div>
      <div class="rules-regulations__content" v-html="content"></div>
      <div class="rules-regulations__button">
        <van-button
          v-if="files.length > 0"
          class="regulations-button__content"
          style="margin-right:20px"
          plain
          type="primary"
          color="#0065FF"
          @click="showAction = true"
        >
          附件 {{ files.length }}
        </van-button>
        <van-button class="regulations-button__content" color="#0065FF" @click="backHistory">
          我已阅读
        </van-button>
      </div>
    </div>
  </van-popup>
  <!-- 下载 -->
  <van-action-sheet
    description="点击下载/查看"
    v-model:show="showAction"
    :actions="actions"
    cancel-text="取消"
    close-on-click-action
    @select="onSelect"
  />
</template>

<script>
import { useVModel } from "@vueuse/core";
import { ref, toRefs } from "vue";
import { ImagePreview } from "vant";
export default {
  name: "rulesRegulations",
  props: {
    content: String,
    show: Boolean,
    files: {
      type: Array,
      default: () => []
    }
  },
  emits: ["update:show"],
  setup(props, { emit }) {
    const { files } = toRefs(props);
    const backHistory = () => {
      emit("update:show", false);
    };
    const showAction = ref(false);
    const actions = files.value.map(file => {
      return { name: file.file_name, type: file.photo_type, url: file.photo_file };
    });

    const onSelect = action => {
      if (["jpg", "jpeg", "png", "gif"].includes(action.type || "")) {
        ImagePreview([action.url || ""]);
        return;
      }
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
        url: `/pages/download/main?url=${action.url}&type=${action.type}&action=0`
      });
    };

    return {
      innerShow: useVModel(props, "show", emit),
      backHistory,
      showAction,
      actions,
      onSelect
    };
  }
};
</script>

<style lang="less" scoped>
.rules-regulations {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__title {
    height: 50px;
    font-size: 15px;
    color: #2d333e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px #e1e3e5 solid;
  }
  &__content {
    flex: 1;
    min-height: 0;
    overflow: auto;
    font-size: 15px;
    color: #333333;
    line-height: 24px;
    padding: 0 15px;
  }
  &__button {
    display: flex;
    margin: 10px 15px 20px;
    .regulations-button__content {
      flex: 1;
      border-radius: 4px;
    }
  }
}
</style>
