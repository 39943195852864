
import { defineComponent, reactive, ref } from "vue";
import { approvalService } from "@/service";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { SubFormItemGroup } from "@/components/check/SubFormItemGroup.vue";
import { Toast, Dialog } from "vant";
import { RouterName } from "@/router";
import rulesRegulations from "@/views/rules-regulations/index.vue";
import { useValidator } from "@/composables";

export default defineComponent({
  name: "ApprovalStart",
  components: {
    rulesRegulations
  },
  setup() {
    const subFormItemGroupRef = ref<SubFormItemGroup | null>(null);
    const route = useRoute();
    const router = useRouter();
    const tempInfo = ref<Approval.TempInfo | null>(null);
    const from = ref(route.query.from);
    // 查看规章制度
    const isShowrules = reactive({
      isRules: false
    });
    const lookRules = () => {
      isShowrules.isRules = !isShowrules.isRules;
    };

    // 获取详情
    const getApprovalInfo = async () => {
      try {
        const { data } = await approvalService.approvalInfo(route.query.detailId as string);
        tempInfo.value = data.data[0] as Approval.TempInfo;

        // 规章制度是否必读
        isShowrules.isRules = Number(tempInfo.value.operating_conditions_json?.is_checked) == 1;
        // router.replace({
        //   query: {}
        // });
      } catch (e) {
        console.log("失败了呢");
      }
    };

    // 获取模板信息
    const getTempInfo = async () => {
      try {
        const { data } = await approvalService.approvalStart(route.params.id as string);
        tempInfo.value = data.data[0];

        // 规章制度是否必读
        isShowrules.isRules = Number(tempInfo.value.operating_conditions_json?.is_checked) == 1;
      } catch (error) {
        console.log("失败了呢");
      }
    };
    // 如果有详情
    if (route.query.detailId) {
      getApprovalInfo();
    } else {
      getTempInfo();
    }

    // 选择人员
    const selectApproveInfo = reactive({
      isShow: false,
      id: "",
      info: {}
    });
    // 提交
    const loading = ref(false);
    // 保险相关
    const policeInfo = reactive<any>({
      dialogShow: false,
      checked: false,
      popupShow: false,
      dialogConfirm() {},
      dialogCancel() {},
      beforeClose(action: string) {
        if (action != "confirm") return true;
        if (policeInfo.checked) return true;
        return false;
      }
    });

    const submit = (check = true) => {
      if (loading.value) return;

      policeInfo.checked = false;

      subFormItemGroupRef.value?.submit(value => {
        if (check && tempInfo.value) {
          const find = useValidator(tempInfo.value?.approval_json, value);
          if (find) {
            Toast.fail(find.message);
            return;
          }
        }

        const approvalStartDo = async (insureId = "") => {
          try {
            loading.value = true;

            const opt = {
              approval_temp_id: route.params.id as string,
              approval_json_result: JSON.stringify(value),
              insure_id: insureId,
              save_temp: check ? "0" : "1"
            };
            if (from.value !== "insure") {
              Object.assign(opt, {
                id: route.query.detailId as string,
                approval_token: tempInfo.value?.approval_token
              });
            }
            const { data } = await approvalService.approvalStartDo(opt);
            // 如果是递归传入自己insureId请求成功就跳列表
            if (insureId) {
              await router.push({
                name: RouterName.MY_APPROVAL,
                replace: true
              });
              return;
            }

            const { h5_url, id, next_node_info, has_insurance, insure_id } = data.data[0];
            // 如果有h5_url就跳支付
            if (h5_url) {
              policeInfo.dialogShow = true;
              await new Promise((resolve, reject) => {
                policeInfo.dialogConfirm = () => {
                  if (policeInfo.checked) {
                    resolve(true);
                    return;
                  }
                  Toast.fail("请阅读客户须知");
                };
                policeInfo.dialogCancel = reject;
              });

              await router.replace({
                query: {
                  detailId: id,
                  from: "insure"
                }
              });

              window.location.href = h5_url;
              return;
            }

            // 2就提示
            if (has_insurance === "2") {
              await Dialog.alert({
                title: "提示",
                message: data.message
              });
              return;
            }
            // 1就弹确认提示框，并重新调自己传入insure_id
            if (has_insurance === "1") {
              await Dialog.confirm({
                title: "提示",
                message: data.message,
                confirmButtonText: "带入"
              });
              approvalStartDo(insure_id);
              return;
            }
            if (!check) {
              Toast.success("保存成功");
              return;
            }
            // 弹人员选择
            selectApproveInfo.id = id;
            selectApproveInfo.info = next_node_info;
            selectApproveInfo.isShow = true;
          } catch (error) {
          } finally {
            loading.value = false;
          }
        };
        approvalStartDo();
      });
    };
    // 选择人员
    const selectApproveSubmit = async (ids: string) => {
      await approvalService.approvalStartSure(selectApproveInfo.id, ids);
      await router.push({
        name: RouterName.MY_APPROVAL,
        replace: true
      });
    };

    onBeforeRouteLeave(async to => {
      if (to.name === RouterName.MY_APPROVAL) {
        return true;
      }
      const answer = window.confirm("退出发起审批操作？");
      if (!answer) return false;
    });

    const saveTemp = async () => {
      Dialog.confirm({
        message: "是否保存到草稿？",
        confirmButtonText: "保存"
      }).then(() => {
        submit(false);
      });
    };

    return {
      submit,
      tempInfo,
      subFormItemGroupRef,
      lookRules,
      isShowrules,
      selectApproveInfo,
      selectApproveSubmit,
      loading,
      policeInfo,
      saveTemp
    };
  }
});
